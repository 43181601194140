<template>
  <div class="container">
    <h2>
      {{ editMode ? "Edit" : "Add" }}
      {{ isPromotion ? "Promotion" : "PromotionBanners" }}
    </h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>

    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>

    <button
      @click="deletePromotionBanners()"
      type="button"
      class="btn btn-danger"
    >
      Delete
    </button>

    <div class="row">
      <div class="col-md-3">
        <label class for="Vehicle">Model</label>
        <VehicleModelSelect v-model="editingPromotionBanners.VehicleId" />
      </div>
      <div class="col-md-2">
        <label class for="Heading">Heading</label>
        <input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="editingPromotionBanners.Heading"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-2">
        <label class for="Copy">Heading Colour</label>

        <select
          style="display: block !important"
          class="form-control"
          v-model="editingPromotionBanners.HeadingColour"
        >
          <option value="#000000">Black</option>
          <option value="#ffffff">White</option>
          <option value="#17a2b8">Teal</option>
          <option value="#002c5f">Navy</option>
        </select>
      </div>

      <div class="col-md-2">
        <label class for="Heading">Heading Fontsize</label>
        <input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="editingPromotionBanners.HeadingFontSize"
          placeholder=" "
          tabindex="0"
          type="number"
        />
      </div>
      <div class="col-md-2">
        <label class for="Copy">Heading Colour Mobile</label>

        <select
          style="display: block !important"
          class="form-control"
          v-model="editingPromotionBanners.HeadingColourMobile"
        >
          <option value="#000000">Black</option>
          <option value="#ffffff">White</option>
          <option value="#17a2b8">Teal</option>
          <option value="#002c5f">Navy</option>
        </select>
      </div>

      <div class="col-md-2">
        <label class for="Copy">Heading Mobile Fontsize</label>
        <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingPromotionBanners.HeadingFontSizeMobile"
          placeholder=" "
          tabindex="0"
          type="number"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <label class for="Copy">Copy</label>
        <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingPromotionBanners.Copy"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>

      <div class="col-md-3">
        <label class for="Copy">Copy Colour</label>

        <select
          style="display: block !important"
          class="form-control"
          v-model="editingPromotionBanners.BodyColour"
        >
          <option value="#000000">Black</option>
          <option value="#ffffff">White</option>
          <option value="#17a2b8">Teal</option>
          <option value="#002c5f">Navy</option>
        </select>
      </div>

      <div class="col-md-3">
        <label class for="Copy">Copy Fontsize</label>
        <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingPromotionBanners.BodyFontSize"
          placeholder=" "
          tabindex="0"
          type="number"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <ImageUploader label="Image" v-model="editingPromotionBanners.Image" />
      </div>

      <div class="col-md-6">
        <ImageUploader
          label="Mobile Image"
          width="428"
          height="668"
          v-model="editingPromotionBanners.MobileImage"
        />
        <!-- width="426"
          height="668" -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <ImageUploader
          :dark="true"
          label="Warranty Image One"
          v-model="editingPromotionBanners.WarrantyImageOne"
        />
      </div>
      <div class="col-md-4">
        <ImageUploader
          :dark="true"
          label="Warranty Image Two"
          v-model="editingPromotionBanners.WarrantyImageTwo"
        />
      </div>

      <div class="col-md-4">
        <ImageUploader label="Bottom logo" v-model="editingPromotionBanners.BottomLogo" />
      </div>
   
    </div>

    <div class="row">
      <div class="col-md-4">
        <label class for="Link">Blue Button Action Link</label>
        <input
          class="form-control"
          id="Link"
          name="Link"
          v-model="editingPromotionBanners.Link"
          placeholder=" "
          tabindex="0"
          type="text"
        />
        <label class for="Link">White Button Action Link</label>
        <input
          class="form-control"
          id="Link"
          name="Link"
          v-model="editingPromotionBanners.Link2"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="LinkFriendly">Blue Button Description</label>
        <input
          class="form-control"
          id="LinkFriendly"
          name="LinkFriendly"
          v-model="editingPromotionBanners.LinkFriendly"
          placeholder=" "
          tabindex="0"
          type="text"
        />
        <label class for="LinkFriendly">White Button Description</label>
        <input
          class="form-control"
          id="LinkFriendly"
          name="LinkFriendly"
          v-model="editingPromotionBanners.LinkFriendly2"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class for="StartingPrice">Starting Price(Including VAT)</label>
        <input
          class="form-control"
          id="StartingPrice"
          name="StartingPrice"
          v-model="editingPromotionBanners.StartingPrice"
          placeholder=" "
          tabindex="0"
          type="text"
        />
        <label class for="StartingPriceNamibia">StartingPriceNamibia</label>
        <input
          class="form-control"
          id="StartingPriceNamibia"
          name="StartingPriceNamibia"
          v-model="editingPromotionBanners.StartingPriceNamibia"
          placeholder="Namibia "
          tabindex="0"
          type="text"
        />
        <label class for="StartingPriceBotswana">StartingPriceBotswana</label>
        <input
          class="form-control"
          id="StartingPriceBotswana"
          name="StartingPriceBotswana"
          v-model="editingPromotionBanners.StartingPriceBotswana"
          placeholder="Botswana"
          tabindex="0"
          type="text"
        />
        <label class for="StartingPriceSwaziland">StartingPriceSwaziland</label>
        <input
          class="form-control"
          id="StartingPriceSwaziland"
          name="StartingPriceSwaziland"
          v-model="editingPromotionBanners.StartingPriceSwaziland"
          placeholder="Swaziland "
          tabindex="0"
          type="text"
        />
      </div>

      <div class="col-md-4">
        <label class for="StartingPrice">Starting Price Heading</label>
        <input
          class="form-control"
          id="StartingFromHeading"
          name="StartingFromHeading"
          v-model="editingPromotionBanners.StartingPriceHeading"
          placeholder=" "
          tabindex="0"
          type="text"
        />

        <b-form-checkbox v-model="editingPromotionBanners.ShowNamibia">
          Show Namibia
        </b-form-checkbox>

        <b-form-checkbox v-model="editingPromotionBanners.ShowBotswana"
          >Show Botswana</b-form-checkbox
        >

        <b-form-checkbox v-model="editingPromotionBanners.ShowSwaziland">
          Show Swaziland
        </b-form-checkbox>

        <b-form-checkbox v-model="editingPromotionBanners.HideSouthAfrica">
          Hide for South Africa
        </b-form-checkbox>
      </div>

      <div class="col-md-4">
        <br />
        <b-form-checkbox v-model="editingPromotionBanners.isPromotion">
          Is Promotion
        </b-form-checkbox>
        <b-form-checkbox v-model="editingPromotionBanners.showExcludingVat">
          Show Excluding VAT
        </b-form-checkbox>

        <label class for="Disclaimer">*Disclaimer</label>
        <input
          class="form-control"
          id="Disclaimer"
          name="Disclaimer"
          v-model="editingPromotionBanners.Disclaimer"
          placeholder=" "
          tabindex="0"
          type="text"
        />

        <label class for="PriceBlockBottom">Price block bottom</label>
        <input
          class="form-control"
          id="PriceBlockBottom"
          name="PriceBlockBottom"
          v-model="editingPromotionBanners.PriceBlockBottom"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class for="RefLayoutId">RefLayoutId</label>
        <input
          class="form-control"
          id="RefLayoutId"
          name="RefLayoutId"
          v-model="editingPromotionBanners.RefLayoutId"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class="" for="Commercial">Commercial</label>
        <br />
        <b-form-checkbox
          v-model="editingPromotionBanners.Commercial"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class for="LastUpdated">LastUpdated</label>
        <input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="editingPromotionBanners.LastUpdated"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class="" for="ShowText">Show Text</label>
        <br />
        <b-form-checkbox
          v-model="editingPromotionBanners.ShowText"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="ShowText">Show Form</label>
        <br />
        <b-form-checkbox
          v-model="editingPromotionBanners.ShowForm"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
      <div class="col-md-4">
        <label class="" for="BottomBanner">Bottom Banner</label>
        <br />
        <b-form-checkbox
          v-model="editingPromotionBanners.BottomBanner"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
    </div>

    <br />
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import ImageUploader from "@/components/custom/ImageUploader.vue";

import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
import VehicleModelSelect from "@/components/custom/VehicleModelSelect.vue";
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

export default {
  name: "EditPromotionBanners",

  data() {
    return {
      originalData: {},
      modifiedData: {},
      isPromotion: false,
    };
  },
  components: {
    ImageUploader,
    VehicleModelSelect,
  },
  created() {
    this.originalData = JSON.parse(
      JSON.stringify(this.editingPromotionBanners)
    );
    this.ImageBytes = this.editingPromotionBanners.Image;
    console.log("editPromotionBanner", this.section);

    this.isPromotion = !!this.$route.query.promotion;
  },

  props: ["editingPromotionBanners", "section", "editMode"],

  methods: {
    toggleView: function () {
      this.uploadedImageUrl = null;

      if (this.editMode) {
        this.$emit("closeEditPromotionBanners");
      } else {
        this.$emit("closeAddPromotionBanners");
      }
    },

    save: function () {
      var data = JSON.parse(JSON.stringify(this.editingPromotionBanners));
      let action = "";
      switch (this.section) {
        case "News":
          action = "editNewsFeedPromotionBanners";
          break;
        case "CorporateSales":
          action = "editCorporateSalesPromotionBanners";
          break;
        default:
          if (this.editMode) {
            action = "editPromotionBanners";
          } else {
            action = "addPromotionBanners";
          }

          break;
      }

      var payload = {
        data: data,
        image: this.uploadedImageData,
        mobileImage: this.uploadedImageDataMobile,
        success: (response) => {
          if (this.editMode) {
            this.$emit("editPromotionBannersSuccess");
          } else {
            this.$emit("addPromotionBannersSuccess");
          }
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch(action, payload);
    },

    imageUploaded: function (file) {
      this.editingPromotionBanners.Image = file;
    },
    imageUploadedMobile: function (file) {
      this.editingPromotionBanners.MobileImage = file;
    },

    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    deletePromotionBanners: function () {
      let action = "";
      switch (this.section) {
        case "News":
          action = "deleteNewsFeedPromotionBanners";
          break;
        case "CorporateSales":
          action = "deleteCorporateSalesPromotionBanners";
          break;
        default:
          action = "deletePromotionBanners";
          break;
      }

      var payload = {
        data: this.editingPromotionBanners,
        success: (response) => {
          if (this.editMode) {
            this.$emit("editPromotionBannersSuccess");
          } else {
            this.$emit("addPromotionBannersSuccess");
          }
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch(action, payload);
    },
  },
};
</script> 
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
