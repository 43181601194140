<template>
  <div class="card">
    <div class="container">
      <b-modal ref="addPromotionBannersModal" hide-footer>
        <addPromotionBanners
          @closeAddPromotionBanners="toggleAddPromotionBanners()"
          @addPromotionBannersSuccess="addPromotionBannersSuccess()"
          :section="section"
        ></addPromotionBanners>
      </b-modal>
      <b-modal ref="editPromotionBannersModal" hide-footer>
        <editPromotionBanners
          :editingPromotionBanners="currentPromotionBanners"
          :section="section"
          :editMode="editMode"
          @closeEditPromotionBanners="toggleEditPromotionBanners()"
          @editPromotionBannersSuccess="editPromotionBannersSuccess()"
          @closeAddPromotionBanners="toggleAddPromotionBanners()"
          @addPromotionBannersSuccess="addPromotionBannersSuccess()"
        ></editPromotionBanners>
      </b-modal>
      <div class="row">
        <h2>
          <br>
          <span :v-if="section">{{ section }} </span> {{ isPromotion ? "Promotions" : "PromotionBanners" }}
        </h2>
      </div>
      <div class="row">
        <button
          @click="toggleAddPromotionBanners()"
          type="button"
          class="btn btn-success"
        >
          Add
        </button>
      </div>
      <div class="row">
        <br />
      <DataBlocks
         
          :showImage='true'
          @itemSelected="editItem"
          :data="items"
          :fields="['Heading']"
        />
      </div>
    </div>
  </div>
</template>
<script>
import addPromotionBanners from "../../components/addPromotionBanners";
import editPromotionBanners from "../../components/editPromotionBanners";
import VueNotifications from "vue-notifications";

import DataBlocks from "@/components/custom/dataBlocks.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  props: ["section"],
  data() {
    return {
      isPromotion: false,
      items: [],
      addPromotionBannersOpen: false,
      editPromotionBannersOpen: false,
      currentPromotionBanners: {},
      editMode: false,
    };
  },
  components: {
    addPromotionBanners,
    editPromotionBanners,
    DataBlocks,
  },
  created: function () {
     this.isPromotion = !!this.$route.query.promotion;
    this.getPromotionBanners();
    this.addPromotionBannersOpen = false;
    this.editPromotionBannersOpen = false;
     
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    getPromotionBanners() {
      
      let action = "";
      console.log("getPromotionBanners", this.section);
      switch (this.section) {
        case "News":
          action = "getNewsFeedPromotionBanners";
          break;
        case "CorporateSales":
          action = "getCorporateSalesPromotionBanners";
          break;
        default:
          action = "getPromotionBanners";
          break;
      }
      if(this.isPromotion){
        action ="getPromotionBannersPromotions";
      }
      console.log("action", action);
      var payload = {
        success: (response) => {
          this.items = response.data;
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch(action, payload);
    },
    editItem(PromotionBanners) {
      this.editMode = true;
      this.toggleEditPromotionBanners();
      this.currentPromotionBanners = PromotionBanners;
    },
    toggleAddPromotionBanners() {
      this.currentPromotionBanners = {};
      this.editMode = false;
      if (this.addPromotionBannersOpen) {
        this.$refs.editPromotionBannersModal.hide();
      } else {
        this.$refs.editPromotionBannersModal.show();
      }
      this.addPromotionBannersOpen = !this.addPromotionBannersOpen;
    },
    addPromotionBannersSuccess() {
      this.toggleAddPromotionBanners();
      miniToastr["success"]("PromotionBanners Added", "Success", 1000, null);
      this.getPromotionBanners();
    },
    toggleEditPromotionBanners() {
      this.editMode = true;
      if (this.editPromotionBannersOpen) {
        this.$refs.editPromotionBannersModal.hide();
      } else {
        this.$refs.editPromotionBannersModal.show();
      }
      this.editPromotionBannersOpen = !this.editPromotionBannersOpen;
    },
    editPromotionBannersSuccess() {
      this.toggleEditPromotionBanners();
      miniToastr["success"]("PromotionBanners Edited", "Success", 1000, null);
      this.getPromotionBanners();
    },
  },
  watch: {
    $route(to, from) {
      this.getPromotionBanners();
    },
  },
};
</script>
<style scoped>
.PromotionBannersThumbnail {
  height: 50px;
}
</style>
